import { BootConfig } from 'types';

const mockBootConfig: BootConfig = {
  isMock: true,
  authInfo: {
    featureFlags: {},
    idLoggedInWebuser: '1',
    permissions: {},
  },
  appDashboardBaseUrl: '/',
  appDashboardApiProxyBaseUri: '/api/pvsinterop',
  useNoLayout: false,
};

const rootDatasetProps =
  document.getElementById('spaRootContainer')?.dataset.customProps;

const bootConfig = rootDatasetProps
  ? (JSON.parse(rootDatasetProps) as BootConfig)
  : mockBootConfig;

export default bootConfig;
