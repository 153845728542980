import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'store/index';
import ThemeProvider from 'theme/ThemeProvider';
import bootConfig from 'utils/bootConfig';
import makeMockServer from 'server';
import GlobalStyleOverrides from './GlobalStyleOverrides';
import BootConfigContext from './components/BootConfigContext/BootConfigContext';
import App from './App';

if (process.env.NODE_ENV === 'development' && bootConfig.isMock) {
  makeMockServer({ environment: 'development' });
}

ReactDOM.createRoot(document.getElementById('spaRootContainer')).render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyleOverrides />
      <ThemeProvider>
        <BootConfigContext.Provider value={bootConfig}>
          <App />
        </BootConfigContext.Provider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
