import { Application } from 'types';

const filterApps = (
  allApps: unknown,
  searchTerm: string,
  selectedTab: 'all' | 'favorites'
): unknown => {
  if (!allApps) {
    return {};
  }

  const filtered = {};

  Object.keys(allApps).forEach((folder) => {
    filtered[folder] = allApps[folder].filter((app: Application) => {
      const matchesSearch = app.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesFavorites =
        selectedTab === 'all' ||
        (selectedTab === 'favorites' && app.isFavorite);
      return matchesSearch && matchesFavorites;
    });

    if (folder !== 'root' && filtered[folder].length === 0) {
      delete filtered[folder];
    }
  });

  return filtered;
};

const hasApps = (apps: unknown): boolean => {
  let count = 0;

  if (apps) {
    Object.keys(apps).forEach((folder) => {
      count += (apps[folder] as Application[])?.length || 0;
    });
  }

  return count > 0;
};

const hasFavorites = (apps: unknown): boolean => {
  if (apps) {
    return Object.keys(apps).some((folder) =>
      (apps[folder] as Application[]).some((app) => app.isFavorite)
    );
  }

  return false;
};

export default {
  filterApps,
  hasApps,
  hasFavorites,
};
