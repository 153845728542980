import React from 'react';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import Avatar from '@mui/material/Avatar';
import { IconButton, COLORS } from 'pvs-design-system';

import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

interface DashboardLinkProps {
  name: string;
  imageUrl: string;
  linkUrl: string;
  isFavorite: boolean;
  applicationId: string;
  handleFavoriteClick: () => void;
}

const DashboardLink: React.FC<DashboardLinkProps> = ({
  name,
  imageUrl,
  linkUrl,
  isFavorite,
  applicationId,
  handleFavoriteClick,
}) => {
  return (
    <ListItem
      sx={{
        backgroundColor: COLORS.beige[200], // '#fbf9f7',
        borderRadius: '6px',
        maxWidth: '442px',
        padding: 0,
        height: '88px',
        '&:hover': {
          backgroundColor: COLORS.beige[200],
          boxShadow: '0 0 0 1px #d3d3d3',
        },
      }}
      secondaryAction={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <IconButton
          color="primary"
          id={`${applicationId}_addFavorite`}
          edge="end"
          aria-label="favorite"
          onClick={handleFavoriteClick}
          sx={{ marginRight: '0px' }}
        >
          {isFavorite ? <Star /> : <StarBorder />}
        </IconButton>
      }
    >
      <ListItemButton
        sx={{
          height: '100%',
          padding: 0,
          borderRadius: '6px',
          '&:focus': {
            boxShadow: '0 0 0 2px #5c1a4d',
            background: '#fdecf5',
          },
          '&:hover': {
            backgroundColor: COLORS.beige[200],
          },
        }}
        onClick={() => window.open(linkUrl, '_blank')}
      >
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            src={imageUrl}
            alt={`${name} image`}
            sx={{
              outline: '1px solid #bbbbbb',
              marginLeft: '16px',
              marginRight: '16px',
            }}
          />
        </ListItemAvatar>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
};

export default DashboardLink;
