import { GlobalStyles } from '@mui/material';
import { TYPOGRAPHY_TOKENS } from 'pvs-design-system';
import React from 'react';
import bootConfig from './utils/bootConfig';

const GlobalStyleOverrides: React.FC = () => {
  return (
    <GlobalStyles
      styles={{
        html: {
          fontSize: bootConfig.useNoLayout ? '10px' : '',
        },
        '#pageTitleHeaderText': {
          padding: '0 !important',
        },
        p: {
          backgroundColor: 'unset',
        },
        '.MuiSnackbar-root': {
          '& .MuiAlert-root': {
            alignItems: 'center',
          },
        },
        '.MuiTablePagination-input': TYPOGRAPHY_TOKENS['interactive-sm'],
      }}
    />
  );
};

export default GlobalStyleOverrides;
