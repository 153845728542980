import React, { useState, useEffect } from 'react';
import { Alert, SearchInput, Typography } from 'pvs-design-system';
import { useGetAppsListQuery, useUpdateAppMutation } from 'api';
import AppList from 'components/AppList/AppList';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Stack, Tabs, Tab } from '@mui/material';
import { Application } from 'types';
import appCollection from 'utils/appCollection';
import { Notify } from 'components/Notifications/Notifications';
import AppsMessage from 'components/AppsMessage/AppsMessage';

const DashboardPage: React.FC = () => {
  const { data: apps, isFetching, isError } = useGetAppsListQuery({});
  const [updateApp] = useUpdateAppMutation();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredApps, setFilteredApps] = React.useState({});
  const [selectedTab, setSelectedTab] = useState<'all' | 'favorites'>('all');
  const [cachedApps, setCachedApps] = useState<typeof apps>(null);
  const [hasApps, setHasApps] = useState(false);
  const [hasFavorites, setHasFavorites] = useState(false);
  const [showNoSearchResults, setShowNoSearchResults] = useState(false);
  const [disableSearch, setDisableSearch] = useState(false);
  const updateHeaderApps = new Event('updateAppDashboardPopover');

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: 'all' | 'favorites'
  ) => {
    setSelectedTab(newValue);
  };

  const handleFavoriteToggle = async (application: Application) => {
    const updatedApplication = {
      ...application,
      isFavorite: !application.isFavorite,
    };

    // Update the local state to reflect the change
    const updatedApps = { ...cachedApps };
    Object.keys(updatedApps).forEach((folder) => {
      updatedApps[folder] = updatedApps[folder].map((m) =>
        m.applicationId === application.applicationId ? updatedApplication : m
      );
    });
    setCachedApps(updatedApps);

    try {
      await updateApp(updatedApplication).unwrap();
      document.dispatchEvent(updateHeaderApps);
    } catch (err) {
      Notify.error(
        `Unable to ${
          !updatedApplication.isFavorite ? 'un' : ''
        }favorite app. Please try again.`
      );
      // Revert optimistic update
      setCachedApps(apps);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Intialize the cachedApps and hasApps state when app data is fetched
  useEffect(() => {
    setCachedApps(apps);
    setHasApps(appCollection.hasApps(apps));
  }, [apps]);

  // Update hasFavorites when cachedApps change
  useEffect(() => {
    if (cachedApps) {
      setHasFavorites(appCollection.hasFavorites(cachedApps));
    }
  }, [cachedApps]);

  // Filter the apps based on the search term and selected tab
  useEffect(() => {
    const foundApps = appCollection.filterApps(
      cachedApps,
      searchTerm,
      selectedTab
    );
    setFilteredApps(foundApps);

    if (selectedTab === 'all') {
      setShowNoSearchResults(
        hasApps && searchTerm !== '' && !appCollection.hasApps(foundApps)
      );
    } else if (selectedTab === 'favorites') {
      setShowNoSearchResults(
        hasApps &&
          hasFavorites &&
          searchTerm !== '' &&
          !appCollection.hasApps(foundApps)
      );
    }
  }, [cachedApps, searchTerm, selectedTab, hasApps, hasFavorites]);

  // Disable search when there are no apps to search
  useEffect(() => {
    setDisableSearch(
      (selectedTab === 'all' && !hasApps) ||
        (selectedTab === 'favorites' && !hasFavorites)
    );
  }, [cachedApps, hasApps, hasFavorites, selectedTab]);

  return (
    <Stack gap={3}>
      <Typography variant="h1">App Dashboard</Typography>
      <LoadingSpinner
        displayText="Loading App Dashboard"
        showLoading={isFetching}
      />
      {!isFetching && (
        <>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="application tabs"
          >
            <Tab label="All Apps" value="all" />
            <Tab label="Favorite Apps" value="favorites" />
          </Tabs>
          <SearchInput
            id="search"
            placeholder="Search Apps"
            ariaLabel="Search Apps"
            autoFocus
            size="medium"
            value={searchTerm}
            onChange={handleSearch}
            disabled={!isFetching && (isError || disableSearch)}
            sx={{
              maxWidth: '44.2rem',
            }}
          />
        </>
      )}
      {!isFetching &&
        !isError &&
        filteredApps &&
        Object.entries(filteredApps).map(([folder, appsList]) => (
          <AppList
            key={folder}
            applications={appsList as unknown as Application[]}
            listName={folder === 'root' ? 'Your Apps' : folder}
            handleFavoriteToggle={handleFavoriteToggle}
          />
        ))}
      {!isFetching && !isError && !hasApps && selectedTab === 'all' && (
        <AppsMessage
          title="No apps to display"
          messageLine1="When apps are available, they will appear here in your App Dashboard."
        />
      )}
      {!isFetching &&
        !isError &&
        !hasFavorites &&
        selectedTab === 'favorites' && (
          <AppsMessage
            title="No favorite apps - yet!"
            messageLine1="To favorite an app, click the star icon next to the app name on your App Dashboard."
            messageLine2="Once you have favorited an app, it will appear here in your Favorite Apps tab."
          />
        )}
      {!isFetching && !isError && showNoSearchResults && (
        <AppsMessage
          title="No results found"
          messageLine1="No apps meet your search criteria, please try again."
        />
      )}
      {!isFetching && isError && (
        <Alert severity="error" sx={{ marginTop: '0.6rem' }}>
          We encountered an error loading the App Dashboard. Please try again.
        </Alert>
      )}
    </Stack>
  );
};

export default DashboardPage;
