import React from 'react';
import { Typography, TYPOGRAPHY_TOKENS as TYPOGRAPHY } from 'pvs-design-system';
import { Stack } from '@mui/material';

interface AppsMessageProps {
  title: string;
  messageLine1: string;
  // eslint-disable-next-line react/require-default-props
  messageLine2?: string;
}

const AppsMessage: React.FC<AppsMessageProps> = (
  { title, messageLine1, messageLine2 } = {
    title: '',
    messageLine1: '',
    messageLine2: '',
  }
) => {
  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      sx={{
        marginTop: '4.7rem',
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: TYPOGRAPHY.h3.fontSize,
        }}
      >
        {title}
      </Typography>
      <Typography variant="p-md" sx={{ textAlign: 'center' }}>
        {messageLine1}
        {messageLine2 && <br />}
        {messageLine2 || ''}
      </Typography>
    </Stack>
  );
};

export default AppsMessage;
