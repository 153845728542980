import React from 'react';
import ErrorPage from 'pages/ErrorPage';
import { createBrowserRouter } from 'react-router-dom';
import Dashboard from 'pages/DashboardPage';

const router = createBrowserRouter([
  {
    // Root path
    path: '/',
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/appdashboard',
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
]);

export default router;
