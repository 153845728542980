import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Box, Stack, Backdrop } from '@mui/material';
import { COLORS } from 'pvs-design-system';
import styled from '@emotion/styled';

interface LoadingSpinnerProps {
  displayText: string;
  showLoading: boolean;
  // eslint-disable-next-line react/require-default-props
  useOverlay?: boolean;
}

const LoadingSpinner = ({
  displayText,
  showLoading,
  useOverlay = false,
}: LoadingSpinnerProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const loadingTimer = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (loadingTimer.current) {
      clearTimeout(loadingTimer.current);
    }
    if (showLoading) {
      loadingTimer.current = setTimeout(() => setIsVisible(true), 500);
    } else {
      setIsVisible(false);
    }
  }, [showLoading]);

  const LoadingStack = styled(Stack)({
    flexDirection: 'column',
    alignItems: 'center',
    gap: '.8rem',
    backgroundColor: COLORS.white,
    padding: useOverlay ? '2rem' : 0,
    marginTop: useOverlay ? '20rem' : '12rem',
    borderRadius: '12px',
  });

  const loadingStack = (
    <LoadingStack>
      <CircularProgress />
      <Box sx={{ fontSize: '1.2rem' }}>{displayText}</Box>
    </LoadingStack>
  );

  return (
    isVisible &&
    (useOverlay ? (
      <Backdrop
        open={isVisible}
        sx={{
          position: 'absolute',
          zIndex: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          alignItems: 'flex-start',
        }}
      >
        {loadingStack}
      </Backdrop>
    ) : (
      loadingStack
    ))
  );
};

export default LoadingSpinner;
