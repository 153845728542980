import React from 'react';
import Application from 'types/Application';
import DashboardLink from 'components/DashboardLink/DashboardLink';
import { List, Box } from '@mui/material';
import {
  Typography,
  COLORS,
  TYPOGRAPHY_TOKENS as TYPOGRAPHY,
} from 'pvs-design-system';

interface AppListProps {
  applications: Application[];
  listName: string;
  handleFavoriteToggle: (application: Application) => void;
}

const AppList: React.FC<AppListProps> = ({
  applications,
  listName,
  handleFavoriteToggle,
}) => {
  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          color: COLORS.gray[400],
          fontSize: TYPOGRAPHY.h5.fontSize,
        }}
      >
        {listName} ({applications?.length ?? 0})
      </Typography>
      <List
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '1.6rem',
          flexDirection: 'row',
          padding: 0,
        }}
      >
        {applications &&
          applications?.map((app) => (
            <DashboardLink
              key={app.assetId}
              applicationId={app.applicationId}
              name={app.name}
              imageUrl={app.imageUrl}
              linkUrl={app.linkUrl}
              isFavorite={app.isFavorite}
              handleFavoriteClick={() => handleFavoriteToggle(app)}
            />
          ))}
      </List>
    </Box>
  );
};

export default AppList;
