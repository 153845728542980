import React from 'react';

const PageTitle: React.FC = () => {
  const pageTitles = {
    '/appdashboard': 'App Dashboard',
  };
  const path = window.location.pathname.toLowerCase();
  document.title = pageTitles[path] ?? 'App Dashboard';

  return null;
};

export default PageTitle;
