import React from 'react';
import styled from '@emotion/styled';
import { Link, useRouteError } from 'react-router-dom';
import { Button, Typography } from 'pvs-design-system';
import { PAGE_TITLE_HEADER_TEXT_ID } from 'constants/constants';
import { Stack } from '@mui/material';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const ErrorPage: React.FC = () => {
  const error = useRouteError();

  return (
    <Container>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Typography
          id={PAGE_TITLE_HEADER_TEXT_ID}
          variant="h1"
          marginBottom={2}
        >
          Page not found
        </Typography>
        <Typography variant="p-md">
          Sorry, an unexpected error has occurred.
        </Typography>
        <Typography variant="p-md" marginBottom={2}>
          Details:{' '}
          {(error as Error)?.message ||
            (error as { statusText?: string })?.statusText}
        </Typography>
        <Link to="/" id="link_to_home_page">
          <Button id="button_to_home_page" color="primary">
            Go to Connexus Home Page
          </Button>
        </Link>
      </Stack>
    </Container>
  );
};

export default ErrorPage;
