import { AlertColor } from '@mui/material';
import { NotificationsStack, useNotificationsContext } from 'pvs-design-system';
import React from 'react';

let notificationsContext: ReturnType<typeof useNotificationsContext>;

export const Notifications: React.FC = () => {
  notificationsContext = useNotificationsContext();
  return <NotificationsStack maxDisplayed={3} />;
};

const notify = (severity: AlertColor, message: string) => {
  notificationsContext.addNotification({
    severity,
    children: message,
  });
};

export const Notify = {
  success(message: string) {
    notify('success', message);
  },
  warning(message: string) {
    notify('warning', message);
  },
  info(message: string) {
    notify('info', message);
  },
  error(message: string) {
    notify('error', message);
  },
};

export default Notifications;
