import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Application } from 'types';
import bootConfig from 'utils/bootConfig';

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: bootConfig.appDashboardApiProxyBaseUri,
  }),
  endpoints: (builder) => ({
    // Apps Endpoints
    getAppsList: builder.query({
      query: () => '/getallapps',
      transformResponse: (response: { applications: Application[] }) => {
        /* 
        // Transform the response group the applications by Folder
        // Example before transformation
        {
          applications: [
            { app1, folder: '' },
            { app2, folder: '' },
            { app3, folder: 'folder1' },
            { app4, folder: 'folder1' },
            { app5, folder: 'folder2' },
            { app6, folder: 'folder2' },
          ]
        }
        // Example after transformation
        {
          root: [ { app1 }, { app2 } ],
          folder1: [ { app3 }, { app4 } ],
          folder2: [ { app5 }, { app6 } ],
        */
        const res = response.applications.reduce((acc, item) => {
          const folder = item.folder || 'root'; // Default to 'root' if folder is empty
          if (!acc[folder]) {
            acc[folder] = [];
          }
          acc[folder].push(item);
          return acc;
        }, {});

        return res;
      },
    }),
    updateApp: builder.mutation<Application, Partial<Application>>({
      query: (app: Application) => {
        return {
          url: `/app/${app.name}`,
          method: 'PUT',
          body: app,
        };
      },
    }),
  }),
});

export const { useGetAppsListQuery, useUpdateAppMutation } = apiSlice;
