import React from 'react';
import { RouterProvider } from 'react-router-dom';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'pvs-design-system';
import { Container } from '@mui/material';
import router from 'router/router';
import { Notifications } from 'components/Notifications/Notifications';
import PageTitle from './components/PageTitle/PageTitle';

const AppContainer = styled(Container)({
  padding: '4rem !important',
  position: 'relative',

  [`@media (max-width: ${BREAKPOINTS.mobileSmall}px)`]: {
    paddingLeft: '.8rem !important',
    paddingRight: '.8rem !important',
  },
});

const App: React.FC = () => {
  return (
    <AppContainer maxWidth="xl">
      <Notifications />
      <RouterProvider router={router} />
      <PageTitle />
    </AppContainer>
  );
};

export default App;
